import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { weightAtom } from "../../../atom/fatloss/weight-atom";
import {
  coinEarnedAmountState,
  coinEarnedAnimationState,
} from "../../../atom/coin/coin-atoms";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./WeightHistory.css";
import { getEndOfDayISOStringZ, getEndOfDayISOStringZyyyyMMdd, getStartOfDayISOStringZ } from "../../../util/Util";
import { userState } from "../../../atom/atoms";

const WeightHistory = () => {
  const navigate = useNavigate();
  const weightState = useRecoilValue(weightAtom);
  const [user, setUser] = useRecoilState(userState);
  const setCoinEarnedAmount = useSetRecoilState(coinEarnedAmountState);
  const setCoinEarnedAnimation = useSetRecoilState(coinEarnedAnimationState);
  const animationCompleted = useRef(false); // Ensure animation runs only once
  const { weightHistory } = weightState;
  const [isFadingOut, setIsFadingOut] = useState(false); // State for fade effect
  const [isFadingOutWeek, setIsFadingOutWeek] = useState(false); // State for Past Week button fade effect
  const [isFadingOutMonth, setIsFadingOutMonth] = useState(false); // State for Past Month button fade effect
  const [isFadingOutAll, setIsFadingOutAll] = useState(false); // State for All button fade effect
  const [filteredData, setFilteredData] = useState([]); // State for filtered data
  const [noData, setNoData] = useState(false); // State to track if there is no data
  const [activeFilter, setActiveFilter] = useState('all'); // State to track the active filter
  const [selectedDate, setSelectedDate] = useState(new Date()); // State for selected date

  // Function to filter data by the selected date
  const filterByDate = (date) => {
    let endOfDate = getEndOfDayISOStringZyyyyMMdd(date);
    const filtered = weightHistory.filter(entry => new Date(entry.weightTxDateTime) <= new Date(endOfDate));
    
    if (filtered.length === 0) {
      setNoData(true); // Set noData to true if no entries match
      setFilteredData([]); // Clear filtered data
    } else {
      setNoData(false); // Reset noData if there are matching entries
      setFilteredData(filtered); // Set filtered data
    }
  };

  // Function to handle date selection
  const handleDateChange = (date) => {
    setSelectedDate(date); // Update selected date
    filterByDate(date); // Filter data based on the selected date
  };

  // Function to filter data by the past week
  const filterByWeek = () => {
    const oneWeekAgo = new Date(getStartOfDayISOStringZ());
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    const weekData = weightHistory.filter(entry => new Date(entry.weightTxDateTime) >= oneWeekAgo);
    setFilteredData(weekData);
    setActiveFilter('week'); // Set active filter to week
    setIsFadingOutWeek(true); // Start fade effect
    setTimeout(() => setIsFadingOutWeek(false), 1000); // Reset fade effect after 1 second
  };

  // Function to filter data by the past month
  const filterByMonth = () => {
    const oneMonthAgo = new Date(getStartOfDayISOStringZ());
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const monthData = weightHistory.filter(entry => new Date(entry.weightTxDateTime) >= oneMonthAgo);
    setFilteredData(monthData);
    setActiveFilter('month'); // Set active filter to month
    setIsFadingOutMonth(true); // Start fade effect
    setTimeout(() => setIsFadingOutMonth(false), 1000); // Reset fade effect after 1 second
  };

  // Function to show all data
  const showAllData = () => {
    setFilteredData(weightHistory);
    setActiveFilter('all'); // Set active filter to all
    setIsFadingOutAll(true); // Start fade effect
    setTimeout(() => setIsFadingOutAll(false), 1000); // Reset fade effect after 1 second
  };

  // Prepare data for Recharts
  const data = filteredData.length > 0 ? filteredData : weightHistory; // Use filtered data if available
  
  const chartData = data.map((entry) => ({
    date: new Date(entry.weightTxDateTime).toLocaleDateString(),
    weight: entry.weightKg,
  }));

  const handleBack = () => {
    navigate("/daily");
  };

  const handlePastWeekClick = () => {
    setIsFadingOutWeek(true);
    filterByWeek();
    // Add logic to fetch and display past week data
    setTimeout(() => {
      setIsFadingOutWeek(false); // Reset fade effect after some time
    }, 1000); // Adjust duration as needed
  };

  const handlePastMonthClick = () => {
    setIsFadingOutMonth(true);
    filterByMonth();
    // Add logic to fetch and display past month data
    setTimeout(() => {
      setIsFadingOutMonth(false); // Reset fade effect after some time
    }, 1000); // Adjust duration as needed
  };

  const handleAllClick = () => {
    setIsFadingOutAll(true);
    showAllData();
    // Add logic to fetch and display all data
    setTimeout(() => {
      setIsFadingOutAll(false); // Reset fade effect after some time
    }, 1000); // Adjust duration as needed
  };

  const getYAxisDomain = () => {
    const minWeight = Math.min(...chartData.map(entry => entry.weight), user?.targetWeight || Infinity);
    const maxWeight = Math.max(...chartData.map(entry => entry.weight), user?.targetWeight || -Infinity);
    
    return [minWeight - 1, maxWeight + 2]; // Adjust the range as needed
  };

  return (
    <div className="weight-history-container">
      <button onClick={handleBack} className="back-button">
        <FontAwesomeIcon icon={faChevronLeft} /> Back
      </button>
      
      {/* Flex row for buttons */}
      <div className="flex flex-row space-x-4 mb-4">
        <button
          onClick={showAllData}
          className={`bg-blue-400 text-white shadow-lg shadow-blue-800/35 px-4 py-2 rounded transition-opacity duration-1000 ${
            isFadingOutAll ? "opacity-50" : "opacity-100"
          } ${activeFilter === 'all' ? 'font-bold uppercase' : 'capitalize'}`} // Bold and uppercase if active filter is 'all'
        >
          All
        </button>
        <button
          onClick={filterByWeek}
          className={`bg-blue-400 text-white shadow-lg shadow-blue-800/35 px-4 py-2 rounded transition-opacity duration-1000 ${
            isFadingOutWeek ? "opacity-50" : "opacity-100"
          } ${activeFilter === 'week' ? 'font-bold uppercase' : 'capitalize'}`} // Bold and uppercase if active filter is 'week'
        >
          Past Week
        </button>
        <button
          onClick={filterByMonth}
          className={`bg-blue-400 text-white shadow-lg shadow-blue-800/35 px-4 py-2 rounded transition-opacity duration-1000 ${
            isFadingOutMonth ? "opacity-50" : "opacity-100"
          } ${activeFilter === 'month' ? 'font-bold uppercase' : 'capitalize'}`} // Bold and uppercase if active filter is 'month'
        >
          Past Month
        </button>
      </div>

      <div className="graph-container">
        <h2>Weight History</h2>
        {noData ? (
          <p>No data available for the selected date.</p> // Message when no data is available
        ) : (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(value) => {
                  const date = new Date(value);
                  if (!isNaN(date)) {
                    let shortDate = value.replace("2024", "").replace("2025","").replace("2026","");
                    shortDate = shortDate
                      .replace("/01/", " Jan")
                      .replace("/02/", " Feb")
                      .replace("/03/", " Mar")
                      .replace("/04/", " Apr")
                      .replace("/05/", " May")
                      .replace("/06/", " Jun")
                      .replace("/07/", " Jul")
                      .replace("/08/", " Aug")
                      .replace("/09/", " Sep")
                      .replace("/10/", " Oct")
                      .replace("/11/", " Nov")
                      .replace("/12/", " Dec");
                    return shortDate;
                  }
                  return value;
                }}
              />
              <YAxis domain={getYAxisDomain()} /> {/* Call a function to get the domain */}
              <Tooltip />
              <Line type="monotone" dataKey="weight" stroke="#8884d8" />
              {/* Add ReferenceLine for targetWeight */}
              {/* Check if targetWeight exists and draw the red line */}
              {user?.targetWeight && (
                <ReferenceLine y={user?.targetWeight} stroke="red" strokeDasharray="3 3" />
              )}
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
      {user?.targetWeight && (
      <div className="text-blue-500 text-center">
        <FontAwesomeIcon icon={faCrosshairs} />
        Target Weight: {user.targetWeight} kg
      </div>
      )}
      <div className="calendar-container">
        <Calendar 
          maxDate={new Date()} // Disable future dates
          onChange={handleDateChange} // Handle date selection
          value={selectedDate} // Set the selected date
        />
      </div>
    </div>
  );
};

export default WeightHistory;
